import { createReducer } from "typesafe-actions";
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from "@microsoft/applicationinsights-common";
import { IRootState } from "../../IRootState";
import { RootActions } from "../../RootActions";
import { initialRootState } from "../../initialRootState";
import { getSharePointPolicies, getSharePointPoliciesFail, getSharePointPoliciesSuccess } from "../../actions/policies/getSharepointPolicies";
import { LoadingState } from "../../../models/ILoading";
import { objectUtils } from "../../../utils";
import { TelemetryService } from "../../../../components/TelemetryService/TelemetryService";
import { EVENTS, API_NAMES, API_STATUS } from "../../../constants";

export const GetSharepointPoliciesReducer = createReducer<IRootState, RootActions>(initialRootState)
.handleAction(getSharePointPolicies, (state) => {
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            isLoading: true,
            loadingState: LoadingState.loading,
            startTime: performance.now()
        }
    };
})
.handleAction(getSharePointPoliciesSuccess, (state, action) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_SHAREPOINT_POLICIES,
      Result: API_STATUS.SUCCESS,
      Duration: Math.trunc(performance.now() - state.sharePointPoliciesRequestState.startTime)
    });
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            data: objectUtils.convertToObjByKeyAsId(action.payload),
            isLoading: false,
            loadingState: LoadingState.loaded
        }
    };
})
.handleAction(getSharePointPoliciesFail, (state, action) => {
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_SHAREPOINT_POLICIES } as Error,
      properties: { 
        API: API_NAMES.GET_SHAREPOINT_POLICIES,
        Payload: action.payload,
        Duration: Math.trunc(performance.now() - state.sharePointPoliciesRequestState.startTime)
      },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            isLoading: false,
            loadingState: LoadingState.error,
            loadingError: action.payload
        }
    };
})