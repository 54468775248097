import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getPolicies, getPoliciesFail, getPoliciesSuccess } from '../../actions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';
import { objectUtils } from '../../../utils';

TelemetryService.initialize(telemetryConfig);

export const getPoliciesReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getPolicies, (state) => {
    return {
      ...state,
      policiesRequestState: {
        ...state.policiesRequestState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getPoliciesSuccess, (state, action) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_POLICIES,
      Result: API_STATUS.SUCCESS,
      Duration: Math.trunc(performance.now() - state.policiesRequestState.startTime)
    });
    return {
      ...state,
      policiesRequestState: {
        ...state.policiesRequestState,
        data: objectUtils.convertToObjByKeyAsId(action.payload),
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getPoliciesFail, (state, action) => {
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_POLICIES } as Error,
      properties: { 
        API: API_NAMES.GET_POLICIES,
        Payload: action.payload,
        Duration: Math.trunc(performance.now() - state.policiesRequestState.startTime)
      },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      policiesRequestState: {
        ...state.policiesRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
