import React, { useEffect } from 'react';
import { Field, useField } from 'formik';
import { Checkbox, css, Link, Text } from '@fluentui/react';
import { MailboxSSSRequestFormStyle } from './MailboxSSSRequestForm.styles';
import { MailboxSSSRequestFormProps } from './MailboxSSSRequestForm.types';
import { SCREEN_SIZE } from '../../../../shared/constants';
import { FormFieldWithTooltip } from '../../FormFieldWithTooltip';
import { FormFieldForChoiceBox } from '../../FormFieldForChoiceBox';

export const MailboxSSSRequestForm = (props: MailboxSSSRequestFormProps) => {
  const {
    formField: {
      requesterEmail,
      crmOrganizationUrl,
      mailboxEmail,
      enableIncomingEmailDelivery,
      enableOutgoingEmailDelivery,
      enableActDeliveryMethod,
      emailServerProfileValidated,
      processEmailFrom,
      description,
      mailboxOwnerApproval,
      enableQueueAccount,
      firewallEnabled,
      ipAllowed
    },
    acceptTerm,
    handleAcceptTermChange
  } = props;

  const [firwallEnabledField] = useField(firewallEnabled.name);
  const [ipAllowedField, ,ipAllowedHelper] = useField(ipAllowed.name);
  useEffect(() => {
    if (firwallEnabledField.value === false) {
      ipAllowedHelper.setValue(undefined);
    }
  }, [firwallEnabledField.value, ipAllowedHelper]);

  return (
    <div className={MailboxSSSRequestFormStyle.form}>
      <div className={MailboxSSSRequestFormStyle.header}>Mailbox SSS Request</div>
      <div
        className={css(
          MailboxSSSRequestFormStyle.wrapper,
          window.innerWidth > SCREEN_SIZE.MEDIUM ? MailboxSSSRequestFormStyle.gridLg : MailboxSSSRequestFormStyle.gridS
        )}
      >
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldWithTooltip
            as={crmOrganizationUrl.component}
            label={crmOrganizationUrl.label}
            name={crmOrganizationUrl.name}
            required={crmOrganizationUrl.required}
            disabled={false}
            tooltip={crmOrganizationUrl.tooltip || ''}
            ariaRequired={crmOrganizationUrl.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldWithTooltip
            as={mailboxEmail.component}
            label={mailboxEmail.label}
            name={mailboxEmail.name}
            required={mailboxEmail.required}
            disabled={false}
            tooltip={mailboxEmail.tooltip || ''}
            ariaRequired={mailboxEmail.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <Field
            as={enableQueueAccount.component}
            label={enableQueueAccount.label}
            name={enableQueueAccount.name}
            required={enableQueueAccount.required}
            disabled={false}
            tooltip={enableQueueAccount.tooltip || ''}
            ariaRequired={enableQueueAccount.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldForChoiceBox
            as={enableIncomingEmailDelivery.component}
            label={enableIncomingEmailDelivery.label}
            name={enableIncomingEmailDelivery.name}
            required={enableIncomingEmailDelivery.required}
            disabled={false}
            tooltip={enableIncomingEmailDelivery.tooltip || ''}
            ariaRequired={enableIncomingEmailDelivery.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldForChoiceBox
            as={enableOutgoingEmailDelivery.component}
            label={enableOutgoingEmailDelivery.label}
            name={enableOutgoingEmailDelivery.name}
            required={enableOutgoingEmailDelivery.required}
            disabled={false}
            tooltip={enableOutgoingEmailDelivery.tooltip || ''}
            ariaRequired={enableOutgoingEmailDelivery.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldForChoiceBox
            as={enableActDeliveryMethod.component}
            label={enableActDeliveryMethod.label}
            name={enableActDeliveryMethod.name}
            required={enableActDeliveryMethod.required}
            disabled={false}
            tooltip={enableActDeliveryMethod.tooltip || ''}
            ariaRequired={enableActDeliveryMethod.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldForChoiceBox
            as={emailServerProfileValidated.component}
            label={emailServerProfileValidated.label}
            name={emailServerProfileValidated.name}
            required={emailServerProfileValidated.required}
            disabled={false}
            tooltip={emailServerProfileValidated.tooltip || ''}
            ariaRequired={emailServerProfileValidated.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldForChoiceBox
            as={mailboxOwnerApproval.component}
            label={mailboxOwnerApproval.label}
            name={mailboxOwnerApproval.name}
            required={mailboxOwnerApproval.required}
            disabled={false}
            tooltip={mailboxOwnerApproval.tooltip || ''}
            ariaRequired={mailboxOwnerApproval.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <FormFieldWithTooltip
            as={processEmailFrom.component}
            label={processEmailFrom.label}
            name={processEmailFrom.name}
            required={processEmailFrom.required}
            disabled={true}
            tooltip={processEmailFrom.tooltip || ''}
            ariaRequired={processEmailFrom.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <Field
            as={requesterEmail.component}
            label={requesterEmail.label}
            name={requesterEmail.name}
            required={requesterEmail.required}
            disabled={true}
            ariaRequired={requesterEmail.required}
          />
        </div>
        <div className={MailboxSSSRequestFormStyle.column1}>
          <Field
            as={firewallEnabled.component}
            label={firewallEnabled.label}
            name={firewallEnabled.name}
            required={firewallEnabled.required}
            disabled={false}
            tooltip={firewallEnabled.tooltip || ''}
            ariaRequired={firewallEnabled.required}
          />
        </div>
        { firwallEnabledField.value?
        (<div className={MailboxSSSRequestFormStyle.column1}>
          <Field
            as={ipAllowed.component}
            label={ipAllowed.label}
            name={ipAllowed.name}
            required={ipAllowed.required}
            disabled={false}
            tooltip={ipAllowed.tooltip || ''}
            ariaRequired={ipAllowed.required}
          />
          {ipAllowedField.value === false ? (
          <div className={MailboxSSSRequestFormStyle.error}>{'Builders Hub IP (20.3.26.214) should be allowlisted in CRM.'}</div>
        ) : null}
        </div>) : null}
      </div>
      <div className={MailboxSSSRequestFormStyle.desc}>
        <FormFieldWithTooltip
          as={description.component}
          label={description.label}
          name={description.name}
          required={description.required}
          multiline
          autoAdjustHeight
          autoComplete="off"
          minHeight="100px"
          ariaRequired={description.required}
          tooltip={description.tooltip || ''}
        />
      </div>
      <div className={MailboxSSSRequestFormStyle.acknowledge}>
        <div style={{ display: 'inline-block' }}>
          <Checkbox
            name="acceptedTerms"
            label={'Required: I have read and agree to the Microsoft Global Data Privacy Notice linked below.'}
            ariaLabel={
              'I have read and agree to the Microsoft Global Data Privacy notice linked below. The terms and conditions must be accepted.'
            }
            checked={acceptTerm}
            onChange={handleAcceptTermChange}
            required={true}
          />
        </div>
        {!acceptTerm ? (
          <div className={MailboxSSSRequestFormStyle.error}>{'The terms and conditions must be accepted.'}</div>
        ) : null}
      </div>
    </div>
  );
};
