/**
 * To get the number of days between two dates
 * @param date1
 * @param date2
 */

const formatDate = (date: string): string => {
  const d = new Date(date);
  let month = (d.getUTCMonth() + 1).toString();
  let day = d.getUTCDate().toString();
  const year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

const noOfDaysDates = (date1: string, date2: string) => {
  const dayInMillisec = 24 * 60 * 60 * 1000;
  const d1 = new Date(formatDate(date1)).getTime();
  const d2 = new Date(formatDate(date2)).getTime();
  const diff = (d1 - d2) / dayInMillisec;

  return parseInt(diff.toString(), 10);
};

export const dateUtils = {
  noOfDaysDates,
  formatDate
};
