export const SPS_CONST = {
  ApiStatus: {
    InViolation: 'In Violation',
    Deleted: 'Deleted',
    Unknown: 'Unknown',
    RestoreRequest: 'Restore In Progress',
    Compliant: 'Compliant'
  },
  StatusStyle: {
    InViolation: 'markNonCompliant',
    Deleted: 'markDeleted',
    Unknown: 'markDeleted',
    RestoreRequest: 'markNonCompliant',
    Compliant: 'markCompliant'
  },
  ActionLabel: {
    InViolation: 'Remediate',
    Deleted: 'Restore',
    Compliant: 'Compliant'
  }
};

export const SPS_API_STATE = {
  IN_VIOLATION: 'InViolation',
  DELETED: 'Deleted',
  UNKNOWN: 'Unknown',
  RESTORE_REQUEST: 'RestoreRequest',
  COMPLIANT: 'Compliant'
};

