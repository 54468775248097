/* eslint-disable no-else-return */
/* eslint-disable no-loop-func */
import * as Yup from 'yup';
import 'regenerator-runtime/runtime';
import { isNullOrUndefined } from '../../../shared/utils/tools/object';

export interface MailboxSSSRequest {
  requesterEmail: string;
  mailboxOwnerApproval: boolean;
  crmOrganizationUrl: string;
  mailboxEmail: string;
  enableIncomingEmailDelivery: boolean;
  enableOutgoingEmailDelivery: boolean;
  enableActDeliveryMethod: boolean;
  processEmailFrom: string;
  description: string;
  emailServerProfileValidated: boolean;
  singleMultipleRequestType: Number;
  requestAction: Number;
  enableQueueAccount: boolean;
  firewallEnabled: boolean;
  ipAllowed: boolean | undefined;
}

export interface MailboxSSSRequestFormValues extends MailboxSSSRequest {}

export const initialMailboxSSSRequestFormValue = {
  requesterEmail: '',
  mailboxOwnerApproval: false,
  crmOrganizationUrl: '',
  mailboxEmail: '',
  enableIncomingEmailDelivery: false,
  enableOutgoingEmailDelivery: false,
  enableActDeliveryMethod: false,
  emailServerProfileValidated: false,
  processEmailFrom: new Date().toISOString(),
  description: '',
  singleMultipleRequestType: 0,
  requestAction: 0
} as unknown as MailboxSSSRequestFormValues;

export const mailboxSSSRequestFormValueValidationSchema = Yup.object({
  requesterEmail: Yup.string()
    .email('Invalid email')
    .test('microsoftEmail', 'Email must be from Microsoft', (value) => {
      if (value) {
        return value.endsWith('microsoft.com');
      }
      return true; // Allow empty values or non-string values
    })
    .required('Requester Email is required!'),
  crmOrganizationUrl: Yup.string().url('Invalid URL').required('CRM Organization URL is required'),
  mailboxEmail: Yup.string()
    .test('invalidEmail', 'Invalid email', (value) => {
      if (value) {
        const emails = value.split(';').map(email => email.trim());
        return emails.every(email => email === '' || Yup.string().email().isValidSync(email));
      }
      return true; // Allow empty values or non-string values
    })
    .required('Mailbox Email is required!'),
  enableIncomingEmailDelivery: Yup.boolean().required('required'),
  enableOutgoingEmailDelivery: Yup.boolean().required('required'),
  enableActDeliveryMethod: Yup.boolean().required('required'),
  emailServerProfileValidated: Yup.boolean().required('required'),
  mailboxOwnerApproval: Yup.boolean().required('required'),
  description: Yup.string().required('Description is required!'),
  enableQueueAccount: Yup.boolean().required('required'),
  firewallEnabled: Yup.boolean().required('required'),
  ipAllowed: Yup.boolean().test('ipAllowedRequired','',(value)=>{
    return isNullOrUndefined(value) || value === true; // return false if defined and not true 
  })
});