import { FormOptions } from '../FormFieldUtil';
import { FormModel } from '../FormModel';
import { FormTextField } from '../Items/FormTextField';
import { FormChoiceGroup } from '../Items/FormChoiceGroup';

export const MailboxSSSRequestFormModel: FormModel = {
  formId: 'mailboxSSSRequestForm',
  formField: {
    requesterEmail: {
      component: FormTextField,
      label: 'Requester Email',
      name: 'requesterEmail',
      required: true
    } as FormOptions,
    crmOrganizationUrl: {
      component: FormTextField,
      label: 'CRM Organization URL',
      name: 'crmOrganizationUrl',
      required: true,
      tooltip: `Provide the URL to your CRM environment (ex. https://ccrm.crm.dynamics.com/).`
    } as FormOptions,
    mailboxEmail: {
      component: FormTextField,
      label: 'Mailbox Email',
      name: 'mailboxEmail',
      required: true,
      tooltip: `Email format must match the email format shown in the Dynamics environment. For example, if the mailbox email in your Dynamics environment uses first.last@microsoft.com (rather than alias@microsoft.com), that format must be used here.
      This email cannot be a Service Account
      If providing more than one email, separate list with semicolons.`
    } as FormOptions,
    enableIncomingEmailDelivery: {
      component: FormChoiceGroup,
      label: 'Enable Incoming Email Delivery',
      name: 'enableIncomingEmailDelivery',
      required: false,
      tooltip: `Select “Yes” to enable server-side synchronization on incoming emails.`
    } as FormOptions,
    enableOutgoingEmailDelivery: {
      component: FormChoiceGroup,
      label: 'Enable Outgoing Email Delivery',
      name: 'enableOutgoingEmailDelivery',
      required: false,
      tooltip: `select “Yes” to enable server-side synchronization on outgoing emails.`
    } as FormOptions,
    enableActDeliveryMethod: {
      component: FormChoiceGroup,
      label: 'Enable Appointments, Contacts and Tasks',
      name: 'enableActDeliveryMethod',
      required: false,
      tooltip: `select “Yes” to enable server-side synchronization for appointments, contacts and tasks.`
    } as FormOptions,
    emailServerProfileValidated: {
      component: FormChoiceGroup,
      label: 'Email Server Profile Validated',
      name: 'emailServerProfileValidated',
      required: true,
      tooltip: `Selecting “Yes” indicates that the mailbox owner consents to server-side synchronization being enabled for the specified mailbox.
      If you are not the owner of the mailbox, the mailbox owner will receive an approval request once this form is submitted.`
    } as FormOptions,
    mailboxOwnerApproval: {
      component: FormChoiceGroup,
      label: 'Mailbox Owner Approval',
      name: 'mailboxOwnerApproval',
      required: true,
      tooltip: `Selecting “Yes” indicates that the mailbox owner consents to server-side synchronization being enabled for the specified mailbox. If you are not the owner of the mailbox, the mailbox owner will receive an approval request once this form is submitted.`
    } as FormOptions,
    processEmailFrom: {
      component: FormTextField,
      label: 'Enable synchronization from date',
      name: 'processEmailFrom',
      required: true,
      tooltip: `Mailbox server-side synchronization will be applied starting from this date at the mailbox level`
    } as FormOptions,
    description: {
      component: FormTextField,
      label: 'Description',
      name: 'description',
      tooltip: 'Provide the description of the mailbox request.',
      required: true
    } as FormOptions,
    enableQueueAccount: {
      component: FormChoiceGroup,
      label: 'Is this for a queue mailbox?',
      name: 'enableQueueAccount',
      required: true,
      tooltip: `Select "Yes" to enable the synchronization for a queue mailbox. Make sure you configured the queue to use your shared mailbox account in the environment's settings.`
    } as FormOptions,
    firewallEnabled: {
      component: FormChoiceGroup,
      label: 'Is "IP address based firewall" enabled for CRM?',
      name: 'firewallEnabled',
      required: true,
      tooltip: `Select "Yes" if feature is enabled by CRM Admin for selected environment.`
    } as FormOptions,
    ipAllowed: {
      component: FormChoiceGroup,
      label: 'Allow Listed Builders Hub IP (20.3.26.214) in CRM',
      name: 'ipAllowed',
      required: true,
      tooltip: `By selecting "Yes", the mailbox owner acknowledges that they have already allow listed the Builders Hub IP in their CRM environment.`
    } as FormOptions
  }
};
