/* eslint-disable radix */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ScrollablePane,
  css,
  ShimmeredDetailsList,
  IColumn,
  SelectionMode,
  MessageBar,
  MessageBarType,
  Stack,
  DefaultButton,
  IIconProps,
  IconButton,
  PivotItem
} from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { useBoolean } from '@uifabric/react-hooks';
import { IComboBoxNumberOption, IPaginationProps, OverflowOption, Pagination } from '@coherence-design-system/pagination';
import { EnvironmentStyle, iconStyles, messageBarStyles } from './Environment.styles';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { _Styles } from '../../../shared/styles/Page.styles';
import { arrayUtils } from '../../../shared/utils';
import { getEnvironments } from '../../../shared/store/actions';
import { IEnvironmentList } from '../../../shared/models/Environment/IEnvironmentList';
import { EnvironmentListProps } from './EnvironmentList.types';
import { EVENTS, GRID, SORT_TYPE, SCREEN_SIZE } from '../../../shared/constants';
import { TelemetryService } from '../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../components/TelemetryService/TelemetryConfig';
import { LoadingState } from '../../../shared/models/ILoading';
import { ErrorBanner } from '../../../components/ErrorBanner';
import { formatEnvironmentsUtil, isCompliant, getActionUrl } from './Environment.helper';
import { ENV_API_STATE, ENV_CONST } from './Environment.constants';
import { getCachedUser } from '../../../msalConfig';
import { EnvironmentEditMetadataPanel } from './Panels/EnvironmentEditMetadataPanel';

export const Environments: React.FC<EnvironmentListProps> = (props) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const history = useHistory();
  const { getEnvironmentsState } = props;
  const [totalEnvironmentsCount, setTotalEnvironmentsCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentOverflowOption, setCurrentOverflowOption] = React.useState<OverflowOption | undefined>();
  const [envList, setEnvList] = useState<IEnvironmentList[]>([]);
  const [sort, setSort] = useState({ sortColumn: '', sortIcon: '', sortType: '' });
  const [environmentId, setEnvironmentId] = useState<string>('');
  TelemetryService.initialize(telemetryConfig);

  const [isMetadataPanelOpen, { setTrue: openMetadataPanel, setFalse: dismissMetadataPanel }] = useBoolean(false);
  const [totalItemsPerPage, setTotalItemsPerPage] = useState(10);
  const editMetadataIcon: IIconProps = { iconName: 'Edit' };

  const handleOverflowClick: NonNullable<IPaginationProps['overflowButtonProps']>['onClick'] = (_event) => {
    const updateOverflow = currentOverflowOption ? undefined : 'overflowAndPageDropdown';
    setCurrentOverflowOption(updateOverflow);
  };

  const handlePivotClick = (
    pivot?: PivotItem | undefined): void => {
    const currentNumber = parseInt((pivot && pivot.props.headerText) || '1',10);
    setCurrentPage(currentNumber);
  };
  const handleOnItemsPerPageChange = (_event: React.FormEvent<IComboBoxNumberOption>, pageSize: number): void => {
    TelemetryService.trackEvent({ name: EVENTS.PAGE_SIZE_CHANGE } as IEventTelemetry, {
      oldPageSize: totalItemsPerPage,
      newPageSize: pageSize,
      username: getCachedUser()?.username
    });
    setCurrentPage(Math.ceil(((currentPage-1) * totalItemsPerPage + 1)/pageSize));
    setTotalItemsPerPage(pageSize);
  };

  const handleOnPageChange = (_event: React.MouseEvent<HTMLButtonElement>, selectedPage: number | undefined): void => {
    if (selectedPage !== undefined) {
      setCurrentPage(selectedPage);
    }
  };

  
  const paginationProps: IPaginationProps = {
    previousPage: {
      ariaLabel: 'previous page'
    },
    nextPage: {
      ariaLabel: 'next page'
    },
    overflowButtonProps: {
      onClick: handleOverflowClick
    },
    pageControls: {
      currentPage: currentPage,
      pages: Math.ceil(totalEnvironmentsCount / totalItemsPerPage),
      overflowOption: currentOverflowOption,
      onPivotClick: handlePivotClick,
      pageDropdownWidth: 60
    },
    onPageChange: handleOnPageChange,
    itemsPerPageProps: {
      currentPageSize: totalItemsPerPage,
      itemsPerPageList: GRID.PAGINATION.PAGE_SIZE_LIST,
      onItemsPerPageChange: handleOnItemsPerPageChange,
      ariaLabel: GRID.PAGINATION.ARIA_LABEL
    }
  };

  const columnSortClicked = (_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
    const newColumn: IColumn = column;
    if (newColumn.iconName === GRID.SORT_DIR.DEFAULT) {
      newColumn.iconName = GRID.SORT_DIR.ASC;
      newColumn.ariaLabel='Environment Column sorted in Ascending order'
    } else if (newColumn.iconName === GRID.SORT_DIR.ASC) {
      newColumn.iconName = GRID.SORT_DIR.DESC;
      newColumn.ariaLabel='Environment Column sorted in Descending order'
    } else {
      newColumn.iconName = GRID.SORT_DIR.DEFAULT;
      newColumn.ariaLabel='Environment Column sorted in Ascending/Descending order'
    }
    setSort({
      sortColumn: newColumn.key,
      sortIcon: newColumn.iconName,
      sortType: newColumn.iconName === GRID.SORT_DIR.ASC ? SORT_TYPE.ASC : SORT_TYPE.DESC
    });
    const columnName = column.key;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const newColumnsList: IColumn[] = arrayUtils.replace.itemByKey(COLUMNS, 'key', columnName, newColumn);
    setColumns(newColumnsList);
    TelemetryService.trackEvent({ name: EVENTS.SORTING_COLUMN } as IEventTelemetry, {
      sortedColumnName: column.key,
      username: getCachedUser()?.name
    });
  };

  const COLUMNS: IColumn[] = [
    {
      key: 'displayName',
      name: '  Environment',
      fieldName: 'displayName',
      maxWidth: 350,
      minWidth: 350,
      iconName: GRID.SORT_DIR.DEFAULT,
      onColumnClick: columnSortClicked,
      isMultiline:true,
      ariaLabel: 'Environment Column header sorted ascending/descending'
    },
    {
      key: 'created',
      name: 'Created Date(UTC)',
      fieldName: 'created',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'violationCount',
      name: '  Status',
      fieldName: 'Status',
      maxWidth: 150,
      minWidth: 150
      // iconName: GRID.SORT_DIR.DEFAULT,
      // onColumnClick: columnSortClicked
      // Commenting sorting on Status temporarily.
    },
    {
      key: 'actionRequired',
      name: '  Action Required',
      fieldName: 'Action Required',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'quarantineDate',
      name: 'Due Date(UTC)',
      fieldName: 'quarantineDate',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'editMetadata',
      name: 'Edit Metadata',
      fieldName: 'Edit Metadata',
      maxWidth: 150,
      minWidth: 150
    }
  ];

  const [columns, setColumns] = useState(COLUMNS);

  useEffect(() => {
    let urlParams = `$top=${totalItemsPerPage}&$skip=${(currentPage - 1) * totalItemsPerPage}&$count=true`;
    if (sort.sortIcon !== GRID.SORT_DIR.DEFAULT && sort.sortColumn) {
      urlParams += `&$orderby=${sort.sortColumn} ${sort.sortType}`;
    } else {
      urlParams += `&$orderby=violationCount ${SORT_TYPE.DESC}`;
    }
    dispatch(getEnvironments(urlParams));
  }, [dispatch, sort, totalItemsPerPage, currentPage]);

  const formatEnvironments = useCallback(formatEnvironmentsUtil, []);

  useEffect(() => {
    if (getEnvironmentsState && getEnvironmentsState.environmentList) {
      setTotalEnvironmentsCount(getEnvironmentsState.environmentList['@odata.count']);
      const list: IEnvironmentList[] = formatEnvironments(getEnvironmentsState.environmentList?.value);
      setEnvList(list);
    }
  }, [getEnvironmentsState, formatEnvironments]);

  const onStatusClick = (id: string | undefined) => {
    TelemetryService.trackEvent({ name: EVENTS.LINK_CLICK } as IEventTelemetry, {
      linkName: 'Voilation Policies',
      id: id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      username: getCachedUser()?.name
    });
  };
  const onRenderItemColumn = (item: IEnvironmentList, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof IEnvironmentList] as string;
    switch (column.key) {
      case 'violationCount':
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        return (
          <div title={ENV_CONST.ApiStatus[item.complianceState]} className={css(iconStyles.statusWrapper)}>
            <div className={css(iconStyles.circle, iconStyles[ENV_CONST.StatusStyle[item.complianceState]])} />
            <span>{ENV_CONST.ApiStatus[item.complianceState]}</span>
          </div>
        );
      case 'editMetadata':
        return (
          <IconButton
            iconProps={editMetadataIcon}
            title="Edit Metadata"
            ariaLabel="Edit Metadata"
            onClick={() => {
              if(item.id){
                setEnvironmentId(item.id);
                openMetadataPanel();
              }
            }}
          />
        );
      case 'actionRequired':
        return !isCompliant(item.executedPolicies) ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <DefaultButton
            aria-label="Press to remediate violation"
            className={css(EnvironmentStyle.actionButton)}
            text={ENV_CONST.ActionLabel[item.complianceState]}
            onClick={() => {
              onStatusClick(item.id);
              const url = getActionUrl(item);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              if (item.complianceState === ENV_API_STATE.DELETED) {
                window.open(url);
              } else {
                history.push(url);
              }
            }}
          />
        ) : (
          <div/>
        );
      case 'quarantineDate':
        return !isCompliant(item.executedPolicies) ? (
          <span>{fieldContent}</span>
        ) : (
          <div />
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  if (getEnvironmentsState?.loadingState === LoadingState.error)
    return (
      <>
        <ScrollablePane
          className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
        >
          <br />
          <ErrorBanner />
        </ScrollablePane>
      </>
    );

  return (
    <div id="environment-list-page">
      <ScrollablePane
        className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
        aria-label="Environments List"
        role="region"
      >
        {isMetadataPanelOpen && (
          <EnvironmentEditMetadataPanel
            selectedEnvironmentId={environmentId}
            isOpen={isMetadataPanelOpen}
            dismissPanel={dismissMetadataPanel}
          />
        )}
        <main data-automation-id="Environments" className={css(EnvironmentStyle.pageBackground)} tabIndex={-1}>
          <div id='no-horizontal-scroll' className={css(EnvironmentStyle.noHorizontalScroll)}>
          <PageHeader pageTitle={'Environments'} />
          <MessageBar messageBarType={MessageBarType.info} styles={messageBarStyles}>
            Note: The status update for your Attestation details may take 24-48 hours after the submission.
          </MessageBar>
          <Stack>
            <div className={css(EnvironmentStyle.listWrapper)}>
              <ShimmeredDetailsList
                columns={columns}
                enableShimmer={props.getEnvironmentsState.isLoading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Environment details"
                items={envList || []}
                onRenderItemColumn={onRenderItemColumn}
                selectionMode={SelectionMode.none}
              />
            </div>
            <Stack horizontalAlign="center" {...(window.innerWidth > SCREEN_SIZE.MEDIUM ? { horizontal: true } : {})}>
                <Pagination {...paginationProps} />
            </Stack>
          </Stack>
          </div>
        </main>
      </ScrollablePane>
    </div>
  );
};
