import { ScrollablePane, ShimmeredDetailsList, Stack, css, IColumn } from '@fluentui/react';
import React from 'react';
import { _Styles } from '../../shared/styles/Page.styles';
import { Assets } from '../../shared/styles/Assets.styles';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export function PBIWorkspaces(props: { isNavCollapsed: any }) {
  const samplePBIWorkspacesList = [
    {
      key: '1',
      displayName: 'Workspace 1',
      created: '2023-01-01T12:00:00Z',
      Status: 'Active',
      'Action Required': 'None',
      deleteDate: '2023-12-31T12:00:00Z'
    },
    {
      key: '2',
      displayName: 'Workspace 2',
      created: '2023-02-01T12:00:00Z',
      Status: 'Inactive',
      'Action Required': 'Review',
      deleteDate: '2023-11-30T12:00:00Z'
    },
    {
      key: '3',
      displayName: 'Workspace 3',
      created: '2023-03-01T12:00:00Z',
      Status: 'Active',
      'Action Required': 'Update',
      deleteDate: '2023-10-31T12:00:00Z'
    }
  ];

  const COLUMNS: IColumn[] = [
    {
      key: 'displayName',
      name: '  Name',
      fieldName: 'displayName',
      maxWidth: 350,
      minWidth: 350,
      isMultiline: true
    },
    {
      key: 'created',
      name: 'Created Date(UTC)',
      fieldName: 'created',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'violationCount',
      name: '  Status',
      fieldName: 'Status',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'actionRequired',
      name: '  Action Required',
      fieldName: 'Action Required',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'deleteDate',
      name: 'Due Date(UTC)',
      fieldName: 'deleteDate',
      maxWidth: 150,
      minWidth: 150
    }
  ];

  return (
    <ScrollablePane
      className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      aria-label="PowerBI workspaces List"
      role="region"
    >
      <main data-automation-id="PBIWorkspaces" className={css(Assets.pageBackground)} tabIndex={-1}>
        <div id="no-horizontal-scroll" className={css(Assets.noHorizontalScroll)}>
          <PageHeader pageTitle={'PowerBI Workspaces'} />
          <Stack>
            <div className={css(Assets.listWrapper)}>
              <ShimmeredDetailsList
                columns={COLUMNS}
                ariaLabelForGrid="PBI Workspaces"
                items={samplePBIWorkspacesList || []}
              />
            </div>
          </Stack>
        </div>
      </main>
    </ScrollablePane>
  );
}
