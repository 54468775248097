import {
  ScrollablePane,
  css,
  Stack,
  DocumentCard,
  DocumentCardDetails,
  DocumentCardImage,
  DocumentCardTitle,
  ImageFit,
  IIconProps,
  MessageBar,
  MessageBarType,
  IMessageBarStyles
} from '@fluentui/react';
import * as React from 'react';
import { _Styles } from '../../shared/styles/Page.styles';
import { PageProps } from './Page.types';
import {
  HomePageCustomSpacingStackTokens,
  HomePageCardStyles,
  HomePageStyle,
  HomePageStackItemStyles
} from './HomePage.styles';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { SPS_LIST_FF, PBI_WORKSPACES_FF } from '../../shared/store/constants';

const environmentIconProps: IIconProps = {
  iconName: 'AddOnlineMeeting',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

const mailboxIconProps: IIconProps = {
  iconName: 'Mail',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

const powerappsIconProps: IIconProps = {
  iconName: 'AppIconDefault',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

const sharepointIconProps: IIconProps = {
  iconName: 'SharepointAppIcon16',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

const pbiWorkspacesIconProps: IIconProps = {
  iconName: 'PowerBILogo16',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

export const HomePage: React.FC<PageProps> = (props) => {
  return (
    <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
      <main data-automation-id="HomePage" className={css(HomePageStyle.pageBackground)} tabIndex={-1}>
        <PageHeader pageTitle={'Builders Hub'} />
        {props.featureFlags?.[SPS_LIST_FF] && (
          <MessageBar messageBarType={MessageBarType.success} style={{ width: '98%' }}>
            Exciting News! <strong>SharePoint is Now Live! </strong>
            You can now manage the governance policies of your SharePoint sites effortlessly using the &quot;Shared
            Workspace&quot; tab.
          </MessageBar>
        )}
        <div className={'ms-DetailsContent'}>
          <div
            className={'Card-Row'}
            style={{
              marginTop: '10%',
              textAlign: 'center'
            }}
          >
            {/* <FocusZone> */}
            <Stack
              horizontal
              horizontalAlign="center"
              wrap
              styles={HomePageStackItemStyles}
              tokens={HomePageCustomSpacingStackTokens}
            >
              <DocumentCard
                aria-label={'Environment Request, Request a power app environment'}
                styles={HomePageCardStyles}
                onClickHref="/environment-request"
              >
                <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={environmentIconProps} />
                <DocumentCardDetails>
                  <DocumentCardTitle title="Environment Request" shouldTruncate />
                  <div style={{ padding: '0px 0px 10px 15px' }}>Request a power app environment</div>
                </DocumentCardDetails>
              </DocumentCard>

              <DocumentCard
                aria-label={'Mailbox SSS Request'}
                styles={HomePageCardStyles}
                onClickHref="/mailbox-request/create"
              >
                <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={mailboxIconProps} />
                <DocumentCardDetails>
                  <DocumentCardTitle title="Mailbox SSS Request" shouldTruncate />
                  <div style={{ padding: '0px 0px 10px 15px' }}>Request a mailbox server side sync in Dynamics</div>
                </DocumentCardDetails>
              </DocumentCard>

              {props.featureFlags?.[SPS_LIST_FF] && (
                <DocumentCard
                  aria-label={'Sharepoint View'}
                  styles={HomePageCardStyles}
                  onClickHref="/shared-workspaces/sharepointsites"
                >
                  <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={sharepointIconProps} />
                  <DocumentCardDetails>
                    <DocumentCardTitle title="SharePoint View" shouldTruncate />
                    <div style={{ padding: '0px 0px 10px 15px' }}>View your SharePoint sites</div>
                  </DocumentCardDetails>
                </DocumentCard>
              )}

              {props.featureFlags?.[PBI_WORKSPACES_FF] && (
                <DocumentCard
                  aria-label={'PowerBI Workspaces View'}
                  styles={HomePageCardStyles}
                  onClickHref="/pbi-workspaces/pbiworkspaces"
                >
                  <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={pbiWorkspacesIconProps} />
                  <DocumentCardDetails>
                    <DocumentCardTitle title="PowerBI Workspaces View" shouldTruncate />
                    <div style={{ padding: '0px 0px 10px 15px' }}>View your PowerBI workspaces</div>
                  </DocumentCardDetails>
                </DocumentCard>
              )}
            </Stack>
            {/* </FocusZone> */}
          </div>
        </div>
      </main>
    </ScrollablePane>
  );
};
