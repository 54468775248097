import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-common';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getSharePointDetail, getSharePointDetailFailed, getSharePointDetailSucceeded } from '../../actions';
import { initialSharePointDetail } from '../../../models/SharepointSites/ISharePointDetailRequests';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

export const getSharePointDetailReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getSharePointDetail, (state) => {
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        data: initialSharePointDetail,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getSharePointDetailSucceeded, (state, action) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_SHAREPOINT_DETAILS,
      Result: API_STATUS.SUCCESS,
      Duration: Math.trunc(performance.now() - state.getSharePointDetailRequestState.startTime)
    });
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        data: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getSharePointDetailFailed, (state, action) => {
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_SHAREPOINT_DETAILS } as Error,
      properties: {
        API: API_NAMES.GET_SHAREPOINT_DETAILS,
        Payload: action.payload,
        Duration: Math.trunc(performance.now() - state.getSharePointDetailRequestState.startTime)
      },
      severityLevel: SeverityLevel.Error,
    } as IExceptionTelemetry);
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
