import { EXECUTED_POLICIES_RESULT } from '../../../shared/constants/enums';
import { IExecutedPolicies } from '../../../shared/models/Environment/IExecutedPolices';
import { ISharePointSitesList } from '../../../shared/models/SharepointSites/ISharePointSitesList';
import { SPS_API_STATE } from './SharePointSites.constants';

/*
@param {IExecutedPolicies[] | undefined} The list of Executed policies from Governance
@returns {boolean} Result of Attestation
*/
export const isCompliant = (executedPolicies: IExecutedPolicies[] | undefined): boolean => {
  if (executedPolicies?.length === 0 || executedPolicies === undefined) return true;
  for (let i = 0; i < executedPolicies?.length; i += 1) {
    if (executedPolicies[i].status === EXECUTED_POLICIES_RESULT.FAIL
    ) {
      return false;
    }
  }
  return true;
};

export const isComplianceStateRestoreorUnknown = (item: ISharePointSitesList): boolean => {
  if (item.complianceState === SPS_API_STATE.RESTORE_REQUEST || item.complianceState === SPS_API_STATE.UNKNOWN)
    return true;
  return false;
};

export const getActionUrl = (item: ISharePointSitesList): string => {
  return `/shared-workspaces/sharepointsites/${item.id}/violations`;
}

/*
@param {any} The Sharepoint list from api
@returns {ISharePointSitesList[]} Formatted Share Point list
*/
export const formatSharePointSitesUtil = (sharePointSiteslist: any): ISharePointSitesList[] => {
  const currentSharePointSites: ISharePointSitesList[] = [];
  if (sharePointSiteslist?.length) {
    for (let i = 0; i < sharePointSiteslist.length; i += 1) {
      const singleSharePointSite: ISharePointSitesList = {
        id: sharePointSiteslist[i]?.id,
        displayName: sharePointSiteslist[i]?.displayName,
        lastCrawlDate: sharePointSiteslist[i]?.lastCrawlDate,
        violationCount: sharePointSiteslist[i]?.violationCount,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        created: sharePointSiteslist[i]?.created?.substring(0, 10),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        deleteDate: sharePointSiteslist[i]?.deleteDate?.substring(0, 10),
        complianceState: sharePointSiteslist[i]?.complianceState,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        executedPolicies: sharePointSiteslist[i].executedPolicies?.map((policy: any) => {
          return {
            policyId: policy.policyId,
            status: policy.status
          };
        }),
        isLoading: false
      };
      currentSharePointSites.push(singleSharePointSite);
    }
  }
  return currentSharePointSites;
};
