import { MessageBarType } from "@fluentui/react";
import { IExecutedPolicies } from "../../../../shared/models/SharepointSites/IExecutedPolices";
import { IHashPolicy } from "../../../../shared/models/policies/ISharepointPolicies";
import { dateUtils } from "../../../../shared/utils";
import { SPS_API_STATE } from "../SharePointSites.constants";
import { VOILATION_FLAG_MSG, VOILATION_STATUS } from "./SharePointViolation.constants";
import { ISharePointViolationPolicy } from "./SharePointViolation.model";

export const fetchFailedExecutedPolicies = (
    ep: IExecutedPolicies[] = [],
    policies: IHashPolicy
  ): ISharePointViolationPolicy[] => {
    const failedEP: ISharePointViolationPolicy[] = [];
    for (let i = 0; i < ep.length; i += 1) {
      const policy: IExecutedPolicies = ep[i];
      if (policy.status === VOILATION_STATUS.FAIL && policy.policyId) {
        const policyData = policies[policy.policyId];
        const failedPolicy: ISharePointViolationPolicy = {
          desc: policyData.description,
          id: policy.policyId,
          info: policyData.referenceUri,
          policy: policyData.displayName,
          action: policy.remediationLink
        };
        failedEP.push(failedPolicy);
      }
    }
    return failedEP;
  };

  export const getDueDateString = (dueDate: string) => {
    let formattedDate = '  ' + dateUtils.formatDate(dueDate);
    // const days = dateUtils.noOfDaysDates(dueDate, new Date().toString());
    // formattedDate += ` (${days} ${days === 1 ? 'day left' : 'days left'})`;
    return formattedDate;
  };

  export const selectClass = (status: string) => {
    switch (status) {
      case SPS_API_STATE.COMPLIANT:
        return MessageBarType.success;
      case SPS_API_STATE.DELETED:
        return MessageBarType.info;
      case SPS_API_STATE.IN_VIOLATION:
        return MessageBarType.warning;
      case SPS_API_STATE.RESTORE_REQUEST:
        return MessageBarType.warning;
      default:
        return MessageBarType.blocked;
    }
  };

  export const getStatusFlag = (status: string) => {
    switch (status) {
      case SPS_API_STATE.QUARANTINED:
        return VOILATION_FLAG_MSG.Quarantined;
      default:
        return null;
    }
  };