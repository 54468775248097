import { mergeStyleSets } from '@fluentui/react';

export const MailboxSSSRequestFormStyle = mergeStyleSets({
  form: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    margin: '20px',
    maxWidth: '1200px'
  },
  wrapper: {
    display: 'grid',
    gap: '20px',
    gridAutoRows: 'minmax(90px, auto)',
    padding: '50px 20px 5px 20px'
  },
  gridS: {
    gridTemplateColumns: 'minmax(150px, 1fr)'
  },
  gridLg: {
    gridTemplateColumns: 'minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)'
  },
  header: {
    height: '47px',
    width: '100%',
    overflow: 'Hidden',
    color: 'black',
    position: 'relative',
    textAlign: 'left',
    paddingLeft: '20px',
    paddingTop: '20px',
    fontSize: '34px',
    fontWeight: 'bold'
  },
  desc: {
    padding: '0px 20px 5px 20px',
    minHeight: '100px'
  },
  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 12% 15px 40px'
  },
  acknowledge: {
    paddingLeft: '20px',
    paddingRight: '10px'
  },
  column1: {
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    top: '4px'
  },
  error: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgb(168, 0, 0)',
    margin: '0px,0px,0px,5px',
    paddingTop: '5px',
    display: 'flex',
    alignItems: 'center'
  },
  linkStyles:{
    color: '#1b78b9',
    textDecoration: 'underline'
  }
});
