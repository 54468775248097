import * as React from 'react';
import { IStyleFunctionOrObject } from '@fluentui/react';
import { CoherenceNav, ICoherenceNavStyleProps, ICoherenceNavStyles } from '@coherence-design-system/controls';
import { getNavigation } from './Routings';
import { NavigationProps, NavigationState } from './Navigation.types';

const overrideNavStyles: IStyleFunctionOrObject<ICoherenceNavStyleProps, ICoherenceNavStyles> = {
  root: {
    borderRight: '1px solid #c1c1c1',
    fontFamily: 'Segoe UI',
    selectors: {
      '[data-id="sharepoint-parent-link"] > div': {
        color: '#036ac4 !important'
      },
      '[data-id="sharepoint-child-link"] > div': {
        color: '#036ac4 !important'
      },
      '[data-id="powerbi-parent-link"] > div > div': {
        color: '##023e8a !important'
      },
      '[data-id="powerbi-child-link"] > div': {
        color: '##023e8a !important'
      }
    }
  }
};

export class NavigationBase extends React.Component<NavigationProps, NavigationState> {
  render() {
    return (
      <CoherenceNav
        appName={'Builders Hub'}
        groups={getNavigation(this.props)}
        onNavCollapsed={this.props.onNavCollapsed}
        styles={overrideNavStyles}
      />
    );
  }
}
