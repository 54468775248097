import * as React from 'react';
import { useField } from 'formik';
import { ChoiceGroup, IChoiceGroupOption, Stack } from '@fluentui/react';
import { ButtonIconWithTooltip } from '../../InfoIcon/ButtonIconWithTooltip';

const options: IChoiceGroupOption[] = [
  { key: true, text: 'Yes' },
  { key: false, text: 'No', styles: { field: { marginLeft: '25px' } } }
];

export function FormChoiceGroup({ ...props }): React.ReactNode {
  const [, meta, helpers] = useField(props.name!);
  return (
    <Stack horizontal>
      <ChoiceGroup
        styles={{
          flexContainer: {
            display: 'flex',
            flex: '2',
            flexDirection: 'row',
            paddingRight: '30px',
            padding: '0 50px 10px 0'
          }
        }}
        aria-required={props.ariaRequired}
        defaultSelectedKey={props.value}
        options={options}
        required={props.required}
        label={props.label}
        onChange={(_, option) => {
          helpers.setValue(option!.key);
        }}
      />
      <ButtonIconWithTooltip tooltipContent={props.tooltip} />
      {meta.touched && meta.error ? (
        <div
          className="error"
          style={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(168, 0, 0)',
            margin: '0px,0px,0px,0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </Stack>
  );
}
